.main-footer {
    position: fixed;
    bottom:0px;
    width:100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.sidebar-dark-indigo .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-indigo .nav-sidebar > .nav-item > .nav-link.active {
    background-color: #005ca9;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #005ca9;
}